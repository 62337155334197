import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios'; // Import Axios

import aboutImage from '../css/images/2.png';
import Header from './Header';

function About() {
  const [aboutData, setAboutData] = useState(null); // State to store fetched data
  const aboutSectionRef = useRef(null);

  useEffect(() => {
    // Check if the hash is '#about' (used for navigation)
    if (window.location.hash === '#about' && aboutSectionRef.current) {
      // Scroll to the About section when the component mounts
      aboutSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    // Fetch data from the API
    axios.get('https://mntaxiservice.com:8888/api/about/list?page=1')
      .then((response) => {
        // Set the fetched data to the state
        setAboutData(response.data.result);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <section id="about" ref={aboutSectionRef} className="about_section layout_padding">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-md-5 offset-lg-2 offset-md-1">
            <div className="detail-box">
              <h2 style={{ color: 'purple' }}>About Us</h2>
              {aboutData ? (
                <p>{aboutData[0].description}</p>
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="img-box" style={{ color: 'purple' }}>
              <img src={aboutImage} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
