import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';

function News() {
  const newsSectionRef = useRef(null);
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://mntaxiservice.com:8888/api/news/list?page=1');
        if (response.data && response.data.success) {
          setNewsData(response.data.result);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleImageError = (event) => {
    event.target.src = 'https://images.unsplash.com/photo-1495020689067-958852a7765e?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';
  };

  const chunkArray = (arr, size) => {
    const chunkedArr = [];
    for (let i = 0; i < arr.length; i += size) {
      chunkedArr.push(arr.slice(i, i + size));
    }
    return chunkedArr;
  };

  const newsChunks = chunkArray(newsData, 3);

  return (
    <section className="news_section layout_padding" id="news" ref={newsSectionRef}>
      <div className="container">
        <div className="heading_container">
          <h2 style={{ color: 'purple' }}>Our News</h2>
        </div>
        {newsChunks.map((chunk, index) => (
          <div className="news_container" key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
            {chunk.map((newsItem) => (
              <div className="box" key={newsItem.id} style={{ width: '100%', maxWidth: '300px', marginBottom: '20px' }}>
                <div className="date-box">
                  <h6>{newsItem.date}</h6>
                </div>
                <div className="img-box">
                  <img
                    src={`https://mntaxiservice.com:8888/uploads/${newsItem.image}`}
                    alt=""
                    onError={handleImageError}
                    style={{ height: '220px', width: '100%' }}
                  />
                </div>
                <div className="detail-box">
                  <h6 style={{ color: 'white' }}>{newsItem.title}</h6>
                  <p style={{ color: 'white' }}>{newsItem.description}</p>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </section>
  );
}

export default News;
