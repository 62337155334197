import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import defaultImage from '../css/images/delivery-man.png'; // Replace 'placeholder.png' with your placeholder image path

function Service() {
  const serviceSectionRef = useRef(null);
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://mntaxiservice.com:8888/api/service/list?page=1');
        if (response.data && response.data.success) {
          setServices(response.data.result);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (window.location.hash === '#service' && serviceSectionRef.current) {
      serviceSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <section className="service_section layout_padding" id="service" ref={serviceSectionRef}>
      <div className="container">
        <div className="heading_container">
          <h2 style={{ color: 'purple' }}>Our Services</h2>
        </div>
        <div className="service_container">
          {services.map((service) => (
            <div className="box" key={service.id}>
              <div className="img-box">
                <img
                  src={
                    service.image
                      ? `https://mntaxiservice.com:8888/uploads/${service.image}`
                      : defaultImage // Using placeholder image when service image is not available
                  }
                  alt=""
                  onError={(e) => {
                    e.target.src = defaultImage; // Handle error loading image by replacing it with a placeholder
                  }}
                />
              </div>
              <div className="detail-box">
                <h5 style={{ color: 'purple' }}>{service.title}</h5>
                <p>{service.description}</p>
                {/* <a href="">Read More</a> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Service;
