import React, { useRef, useEffect, useState,useCallback } from "react";
import sliderImage from "../css/images/2.png";
import { loadStripe } from "@stripe/stripe-js";
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';


import {NotificationContainer, NotificationManager} from 'react-notifications';


function Slider() {
  const sliderSectionRef = useRef(null);
  const phoneNumber = '952-737-9463'; // Replace with your desired phone number
  // const phoneNumber2 = '(612) 546 - 9008';
  const [paymentSubmitted, setPaymentSubmitted] = useState(false); // Track payment submission
  const [vehicleTypes, setVehicleTypes] = useState([]); // Store the vehicle types
  const [loadingVehicleTypes, setLoadingVehicleTypes] = useState(true);
  
  const [formData, setFormData] = useState({
    firstname: "",
    phone: "",
    email: "",
    pickup: "",
    destination: "",
    pickupDate: "",
    pickupTime: "",
    vehicleType: "",
    description: "",
    estimatedFare: "",
  });
  const [distance, setDistance] = useState(0);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Fetch vehicle types from the API
    fetch('https://mntaxiservice.com:8888/api/vehicleType/list?page=1')
      .then(response => response.json())
      .then(data => {
        if (data.success && data.result) {
          setVehicleTypes(data.result);
          setLoadingVehicleTypes(false);
        } else {
          console.error('Failed to fetch vehicle types from the API.');
        }
      })
      .catch(error => {
        console.error('An error occurred while fetching vehicle types from the API:', error);
      });
  }, []);
  const handleVehicleTypeChange = useCallback((event) => {
    const selectedType = event.target.value;
    setFormData({
      ...formData,
      vehicleType: selectedType,
    });
  }, [formData]);

  useEffect(() => {
    if (window.location.hash === "#slider" && sliderSectionRef.current) {
      sliderSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCPUDU0R4IutXMlinajGnm1tNQvUpoSNVs&libraries=places`;
    script.async = true;
    script.defer = true;

    script.onload = () => {
      setLoading(false);
      const pickupInput = document.getElementById("pickup-input");
      const destinationInput = document.getElementById("destination-input");

      if (pickupInput && destinationInput) {
        const options = {
          types: ["geocode"],
          componentRestrictions: { country: "US" },
        };

        const pickupAutocomplete = new window.google.maps.places.Autocomplete(
          pickupInput,
          options
        );
        const destinationAutocomplete =
          new window.google.maps.places.Autocomplete(destinationInput, options);

        pickupAutocomplete.addListener("place_changed", () => {
          const place = pickupAutocomplete.getPlace();
          setFormData({
            ...formData,
            pickup: place.formatted_address,
          });

          if (formData.destination) {
            calculateDistanceAndFare();
          }
        });

        destinationAutocomplete.addListener("place_changed", () => {
          const place = destinationAutocomplete.getPlace();
          setFormData({
            ...formData,
            destination: place.formatted_address,
          });

          if (formData.pickup) {
            calculateDistanceAndFare();
          }
        });
      }
    };

    document.head.appendChild(script);
  }, [formData]);

  // ...

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "pickup" || name === "destination") {
      // Check if the new value is an empty string
      if (value === "") {
        // Clear the form data and recalculate distance and fare if either "pickup" or "destination" is cleared
        setFormData({
          firstname: "",
          phone: "",
          email: "",
          pickup: name === "pickup" ? value : formData.pickup,
          destination: name === "destination" ? value : formData.destination,
          pickupDate: "",
          pickupTime: "",
          vehicleType: "",
          description: "",
          estimatedFare: "",
        });
        setDistance(0);
      } else {
        // If the value is not empty, clear the fare and allow for editing
        setFormData({
          ...formData,
          [name]: value,
          estimatedFare: "", // Clear the fare
        });
        setDistance(0);
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    // Recalculate distance and estimated fare when input fields are updated
    if (name === "pickup" || name === "destination") {
      calculateDistanceAndFare();
    }
    if (name === "estimatedFare" && value !== "") {
      setPaymentSubmitted(true);
    }
  };

  // ...

  // const calculateDistanceAndFare = () => {
  //   const distanceMatrixService =
  //     new window.google.maps.DistanceMatrixService();

  //   distanceMatrixService.getDistanceMatrix(
  //     {
  //       origins: [formData.pickup],
  //       destinations: [formData.destination],
  //       travelMode: window.google.maps.TravelMode.DRIVING,
  //     },
  //     (response, status) => {
  //       if (status === "OK" && response.rows[0].elements[0].status === "OK") {
  //         const distanceInMeters = response.rows[0].elements[0].distance.value;
  //         const distanceInMiles = distanceInMeters * 0.000621371;
  //         setDistance(distanceInMiles);

  //         setFormData({
  //           ...formData,
  //           estimatedFare: (distanceInMiles * 2).toFixed(2),
  //         });
  //       }
  //     }
  //   );
  // };

  // const calculateDistanceAndFare = () => {
  //   // Fetch the value from the API
  //   fetch('https://mntaxiservice.com:8888/api/pricePerMile/list?page=1')
  //     .then(response => response.json())
  //     .then(data => {
  //       console.log("data",data.result[0].description)
  //       if (data && data.result[0].description) {
  //         const perMileRate =parseFloat(data.result[0].description) ; // Assuming the value you need is in 'value' field
  //         const distanceMatrixService = new window.google.maps.DistanceMatrixService();
  
  //         distanceMatrixService.getDistanceMatrix(
  //           {
  //             origins: [formData.pickup],
  //             destinations: [formData.destination],
  //             travelMode: window.google.maps.TravelMode.DRIVING,
  //           },
  //           (response, status) => {
  //             if (status === "OK" && response.rows[0].elements[0].status === "OK") {
  //               const distanceInMeters = response.rows[0].elements[0].distance.value;
  //               const distanceInMiles = distanceInMeters * 0.000621371;
  //               setDistance(distanceInMiles);
  
  //               setFormData({
  //                 ...formData,
  //                 estimatedFare: (distanceInMiles * perMileRate).toFixed(2),
  //               });
  //             }
  //           }
  //         );
  //       } else {
  //         console.error('Unable to fetch the per mile rate from the API.');
  //       }
  //     })
  //     .catch(error => {
  //       console.error('An error occurred while fetching data from the API:', error);
  //     });
  // };
  
  const calculateDistanceAndFare = () => {
    fetch('https://mntaxiservice.com:8888/api/pricePerMile/list?page=1')
      .then(response => response.json())
      .then(data => {
        const pricePerMile = data.result.find(item => item.title === 'PRICE_PER_MILE');
        const basePrice = data.result.find(item => item.title === 'BASE_PRICE');
  
        if (pricePerMile && basePrice) {
          const perMileRate = parseFloat(pricePerMile.description);
          const baseRate = parseFloat(basePrice.description);
  
          const distanceMatrixService = new window.google.maps.DistanceMatrixService();
  
          distanceMatrixService.getDistanceMatrix(
            {
              origins: [formData.pickup],
              destinations: [formData.destination],
              travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (response, status) => {
              if (status === 'OK' && response.rows[0].elements[0].status === 'OK') {
                const distanceInMeters = response.rows[0].elements[0].distance.value;
                const distanceInMiles = distanceInMeters * 0.000621371;
                setDistance(distanceInMiles);
  
                const fareBasedOnMiles = distanceInMiles * perMileRate;
                console.log("fareBasedOnMiles", fareBasedOnMiles);

                const totalFare = fareBasedOnMiles + baseRate;
                console.log("totalFare", totalFare);
  
                setFormData({
                  ...formData,
                  estimatedFare: totalFare.toFixed(2),
                });
              }
            }
          );
        } else {
          console.error('Unable to fetch the required rates from the API.');
        }
      })
      .catch(error => {
        console.error('An error occurred while fetching data from the API:', error);
      });
  };
  
  const handleCalculateFareClick = () => {
    if (formData.pickup && formData.destination) {
      calculateDistanceAndFare();
    }
  };

  useEffect(() => {
    // Load the Square SDK here
    if (!window.square) {
      const script = document.createElement('script');
     // script.src = 'https://sandbox.web.squarecdn.com/v1/square.js';
      script.src = 'https://js.squareup.com/v2/paymentform';
    
      script.async = true;
  
      // Define a function to initialize the Square SDK once the script is loaded
      const initializeSquareSDK = () => {
        // Replace with your Square application credentials
        const applicationId = 'sq0idp-L851W0DUgFkBQ2PVizxS8Q';
        const locationId = 'LTMT1YS7ZG0QW';
  
        // Check if the Square SDK is loaded
        if (window.square) {
          // Create a new Square PaymentsForm instance
          const paymentsForm = new window.square.PaymentsForm(applicationId, locationId);
  
          // Use the paymentsForm to generate a card token
          const generateCardToken = async () => {
            const { status, token, error } = await paymentsForm.generateCardToken();
            if (status === 'OK') {
              // Handle the successful card token
              handleCardTokenReceived(token);
              console.info({ token });
            } else {
              // Handle any errors
              console.error('Error generating card token:', error);
            }
          };
  
          // Store the generateCardToken function for later use
          window.generateCardToken = generateCardToken;
        } else {
          console.error('Square SDK is not loaded');
        }
      };
  
      script.onload = initializeSquareSDK; // Initialize Square SDK once script is loaded
  
      document.head.appendChild(script);
    }
  }, []);
  
  const handleCardTokenReceived = (token) => {
    // This function is called when the card token is received from the Square Payment Form
    sendCardTokenToServer(token); // Send the card token to your server for payment processing
  };

  // useEffect(() => {
  //   // Load the Square SDK here
  //   if (!window.square) {
  //     const script = document.createElement('script');
  //     script.src = 'https://js.squareup.com/v2/paymentform';
  //     script.async = true;
  
  //     script.onload = () => {
  //       // Square SDK is loaded, you can now use it
  //       // Place your Square SDK initialization code here
  //     };
  
  //     document.head.appendChild(script);
  //   }
  // }, []);
  
  
  const handlePayment = async () => {
setPaymentSubmitted(true)

  };
  
  const sendCardTokenToServer = async (token) => {
    try {

      const cardToken = token; // Replace with the actual card token obtained earlier
      const amount = parseFloat(formData.estimatedFare) * 100; // Amount in cents, adjust as needed
      console.log(amount)
  
      fetch('https://mntaxiservice.com:8888/initiate-payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cardToken, amount }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            // Payment succeeded, you can take further actions, e.g., submit the reservation
            handleSubmit();
          } else {
            NotificationManager.error('Payment failed', data.error);
          }
        })
        .catch((error) => {
          NotificationManager.error('Payment failed', error);
        });
    }
   //  handleSubmit()
    catch (error) {
      console.error("Error sending card token to server:", error);
    }
  };
  // const handlePayment = async () => {
  //   if (formData.pickup && formData.destination) {
  //     try {
  //       const response = await fetch(
  //         "http://mntaxiservice.com:8888/create-checkout-session",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({
  //             lineItems: [{ price: "your_price_id", quantity: 1 }],
  //             successUrl: "https://yourwebsite.com/success",
  //             cancelUrl: "https://yourwebsite.com/cancel",
  //           }),
  //         }
  //       );

  //       const data = await response.json();

  //       const stripe = await stripePromise; // Load Stripe.js
  //       const result = await stripe.redirectToCheckout({
  //         sessionId: data.id, // Use the session ID from the response
  //       });

  //       if (result.error) {
  //         console.error(result.error.message);
  //       }
  //     } catch (error) {
  //       console.error("Error handling payment:", error);
  //     }
  //   }
  // };

  // const handlePayment = async () => {
  //   // Call your server to create the Payment Intent and receive the clientSecret
  //   const response = await fetch('http://mntaxiservice.com:8888/create-payment-intent', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       amount: formData.estimatedFare * 100, // Amount in cents
  //       currency: 'usd',
  //       // Add other payment intent details here
  //     }),
  //   });

  //   const data = await response.json();

  //   // Redirect to Stripe using the clientSecret
  //   const stripe = await stripePromise; // Load Stripe.js
  //   const result = await stripe.redirectToCheckout({
  //     sessionId: data.clientSecret, // Use the clientSecret from the Payment Intent
  //   });

  //   if (result.error) {
  //     console.error(result.error.message);
  //   }
  // };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   const fullDateTime = `${formData.pickupDate}T${formData.pickupTime}`;
  //   const fullTime = `${formData.pickupDate}T${formData.pickupTime}`;
  //   setFormData({
  //     ...formData,
  //     pickupDate: fullDateTime,
  //     pickupTime: fullTime,
  //   });

  //   try {
  //     const response = await fetch(
  //       "http://mntaxiservice.com:8888/api/reservation/create",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(formData),
  //       }
  //     );

  //     if (response.ok) {
  //       alert("Reservation submitted successfully.");
  //       setFormData({
  //         firstname: "",
  //         phone: "",
  //         email: "",
  //         pickup: "",
  //         destination: "",
  //         pickupDate: "",
  //         pickupTime: "",
  //         vehicleType: "",
  //         description: "",
  //         estimatedFare: "",
  //       });
  //     } else {
  //       alert("Failed to submit reservation.");
  //     }
  //   } catch (error) {
  //     console.error("Error submitting reservation:", error);
  //   }
  // };

  const handleSubmit = async (event) => {
 //   event.preventDefault();
    const fullDateTime = `${formData.pickupDate}T${formData.pickupTime}`;
    const fullTime = `${formData.pickupDate}T${formData.pickupTime}`;
    setFormData({
      ...formData,
      pickupDate: fullDateTime,
      pickupTime: fullTime,
    });

    try {
      const response = await fetch(
        "https://mntaxiservice.com:8888/api/reservation/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        // alert("Reservation submitted successfully.");
        setPaymentSubmitted(false)
        NotificationManager.success('Your trip is reserved successfully', 'Reservation Completed.');
      
        setFormData({
          firstname: "",
          phone: "",
          email: "",
          pickup: "",
          destination: "",
          pickupDate: "",
          pickupTime: "",
          vehicleType: "",
          description: "",
          estimatedFare: "",
        });
      } else {
        NotificationManager.success('Payment failed');

        //alert("Failed to submit reservation.");
      }
    } catch (error) {
      NotificationManager.success('Payment failed', error);
    }
  };


  // ...

  return (

    <div className="hero_area" id="slider" style={{
     marginTop: '105px'   
     // Adjust z-index if needed
    }} ref={sliderSectionRef}>
      <section className="slider_section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7 col-md-12">
              <div className="box">
                <div
                  id="carouselExampleIndicators"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="img-box">
                        <img src={sliderImage} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <br />
              <br />
              <br />
              <br />
              <div className="slider_form"  style={{ color: 'purple', width:'355px' }}>
            <h4> 
        <a href={`tel:${phoneNumber}`} style={{ color: 'yellow' }} >   <LocalPhoneIcon /> {phoneNumber}</a> <br />
    
        
        </h4>
          
  
                <h4>Or Reserve Here. </h4>
                <div>
                <NotificationContainer/>

                </div>

                {loading ? (
                  <p>Loading...</p>
                ) : paymentSubmitted ? ( // Render payment form if payment is submitted
                  // <PaymentForm
                  //   applicationId="sandbox-sq0idb-ppQaKuF40zODvoRfRbci2Q"
                  //   locationId="LTMT1YS7ZG0QW"
                  //   cardTokenizeResponseReceived={(token, buyer) => {
                  //     console.info({ token, buyer });
                  //   }}
                  //   createVerificationDetails={() => ({
                  //     amount: '1.00',
                  //     billingContact: {
                  //       addressLines: ['123 Main Street', 'Apartment 1'],
                  //       familyName: 'Doe',
                  //       givenName: 'John',
                  //       countryCode: 'GB',
                  //       city: 'London',
                  //     },
                  //     currencyCode: 'GBP',
                  //     intent: 'CHARGE',
                  //   })}
                  // >
                  //   <CreditCard />
                  // </PaymentForm>
                  <PaymentForm
  applicationId="sq0idp-L851W0DUgFkBQ2PVizxS8Q"
  locationId="LTMT1YS7ZG0QW"
  cardTokenizeResponseReceived={(token, buyer) => {
    console.info({ token, buyer });
    handleCardTokenReceived(token); // Call your handler when the card token is received
  }}
  // createVerificationDetails={() => ({
  //   amount: '10000.00',
  //   billingContact: {
  //     addressLines: ['123 Main Street', 'Apartment 1'],
  //     familyName: 'Doe',
  //     givenName: 'John',
  //     countryCode: 'GB',
  //     city: 'London',
  //   },
  //   currencyCode: 'GBP',
  //   intent: 'CHARGE',
  // })}
>
  <CreditCard />
</PaymentForm>

                ) : (
                  <div className="form-container">
                    <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="firstname"
                        placeholder="Full Name"
                        value={formData.firstname}
                        onChange={handleInputChange}
                        required
                      />
                      <input
                        type="text"
                        name="phone"
                        placeholder="Phone Number"
                        value={formData.phone}
                        onChange={handleInputChange}
                        required
                      />
                      <input
                        type="text"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                      />
                      <input
                        type="text"
                        name="pickup"
                        id="pickup-input"
                        placeholder="Pickup Location"
                        value={formData.pickup}
                        onChange={handleInputChange}
                        required
                      />
                      <input
                        type="text"
                        name="destination"
                        id="destination-input"
                        placeholder="Destination"
                        value={formData.destination}
                        onChange={handleInputChange}
                        required
                      />
                      <input
                        type="date"
                        name="pickupDate"
                        placeholder="Pickup Date"
                        value={formData.pickupDate}
                        onChange={handleInputChange}
                        required
                      />
                      <input
                        type="time"
                        name="pickupTime"
                        placeholder="Pickup Time"
                        value={formData.pickupTime}
                        onChange={handleInputChange}
                        required
                      />
                      <select
                        name="vehicleType"
                        value={formData.vehicleType}
                        onChange={handleVehicleTypeChange}
                        required
                      >
                        <option value="">Select Vehicle Type</option>
                        {vehicleTypes.map(type => (
                          <option key={type.id} value={type.title}>
                            {type.title}
                          </option>
                        ))}
                      </select>
                      <textarea
                        name="description"
                        placeholder="Description"
                        className="message_input"
                        value={formData.description}
                        onChange={handleInputChange}
                        required
                        style={{ width: "100%" }}
                      ></textarea>
                      <input
                        type="text"
                        disabled
                        hidden
                        name="estimatedFare"
                        placeholder="Estimated Fare"
                        value={formData.estimatedFare}
                      />
                      <div className="fare-container">
                        {formData.pickup && formData.destination && (
                          <button
                            type="button"
                            onClick={handleCalculateFareClick}
                          >
                            Calculate Fare
                          </button>
                        )}
                        {formData.estimatedFare && (
                          <div className="estimated-fare">
                            Estimated Fare:{" "}
                            <span className="white-bold-text">
                              ${formData.estimatedFare}
                            </span>
                          </div>
                        )}
                      </div>
                      <button
                        type="button"
                        onClick={handlePayment}
                        disabled={!formData.estimatedFare}
                      >
                        Pay and Submit Reservation
                      </button>
                    </form>
                  </div>
                )}
                {/* {loading ? (
                  <p>Loading...</p>
                ) : showPaymentForm ? ( // Conditionally render the payment form
                  <PaymentForm
                    applicationId="sandbox-sq0idb-ppQaKuF40zODvoRfRbci2Q"
                    locationId="LTMT1YS7ZG0QW"
                    cardTokenizeResponseReceived={(token, buyer) => {
                      console.info({ token, buyer });
                    }}
                    createVerificationDetails={() => ({
                      amount: '1.00',
                      billingContact: {
                        addressLines: ['123 Main Street', 'Apartment 1'],
                        familyName: 'Doe',
                        givenName: 'John',
                        countryCode: 'GB',
                        city: 'London',
                      },
                      currencyCode: 'GBP',
                      intent: 'CHARGE',
                    })}
                  >
                    <CreditCard />
                  </PaymentForm>
                ) : (
                  <div className="form-container">
                    <form onSubmit={handleSubmit}>
                      <input
                        type="text"
                        name="firstname"
                        placeholder="Full Name"
                        value={formData.firstname}
                        onChange={handleInputChange}
                      />
                      <input
                        type="text"
                        name="phone"
                        placeholder="Phone Number"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                      <input
                        type="text"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                      <input
                        type="text"
                        name="pickup"
                        id="pickup-input"
                        placeholder="Pickup Location"
                        value={formData.pickup}
                        onChange={handleInputChange}
                      />
                      <input
                        type="text"
                        name="destination"
                        id="destination-input"
                        placeholder="Destination"
                        value={formData.destination}
                        onChange={handleInputChange}
                      />
                      <input
                        type="date"
                        name="pickupDate"
                        placeholder="Pickup Date"
                        value={formData.pickupDate}
                        onChange={handleInputChange}
                      />
                      <input
                        type="time"
                        name="pickupTime"
                        placeholder="Pickup Time"
                        value={formData.pickupTime}
                        onChange={handleInputChange}
                      />
                      <input
                        type="text"
                        name="vehicleType"
                        placeholder="Vehicle Type"
                        value={formData.vehicleType}
                        onChange={handleInputChange}
                      />
                      <textarea
                        name="description"
                        placeholder="Description"
                        className="message_input"
                        value={formData.description}
                        onChange={handleInputChange}
                        style={{ width: "100%" }}
                      ></textarea>
                      <input
                        type="text"
                        disabled
                        hidden
                        name="estimatedFare"
                        placeholder="Estimated Fare"
                        value={formData.estimatedFare}
                      />
                      <div className="fare-container">
                        {formData.pickup && formData.destination && (
                          <button
                            type="button"
                            onClick={handleCalculateFareClick}
                          >
                            Calculate Fare
                          </button>
                        )}
                        {formData.estimatedFare && (
                          <div className="estimated-fare">
                            Estimated Fare:{" "}
                            <span className="white-bold-text">
                              ${formData.estimatedFare}
                            </span>
                          </div>
                        )}
                      </div>

                      <button
                        type="button"
                        onClick={handlePayment}
                        disabled={!formData.estimatedFare}
                      >
                        Pay and Submit Reservation
                      </button>
                    </form>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Slider;
